import Vue from "vue";
import Utility from "../../../shared/utility.js";
import EncryptUtility from "./encrypt-utility.js";
import AddFavourite from "./addFav.js";
import breadcrumbComp from "../../common/breadcrumb-comp";
import excel from "vue-excel-export";
Vue.use(excel);
export default {
  data() {
    return {
      refresh: true,
      backSlash: true,
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      searchValid: false,
      searchValue: "",
      primaryLabelSize: "",
      primaryPrinterType: "",
      pass: "",
      headersSearch: [],
      headersPopup: [],
      dataSearched: false,
      headerData: [],
      resultData: [],
      columnInfo: [],
      keys: [],
      clickValue: [],
      labelData: [],
      showLabelData: false,
      excelName: "",
      modaldialog: false,
      excelText: "",
      max50Rule: [(v) => !!v || "Field is required", (v) => (v || "").length <= 50 || "Max Length of 50 character"],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {},
  methods: {
    //To add or remove Favourite issue
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //Reset Function
    resetFunction() {
      this.searchValid = false;
      this.searchValue = "";
      this.pass = "";
      this.headersSearch = [];
      this.headersPopup = [];
      this.headerData = [];
      this.dataSearched = false;
      this.resultData = [];
      this.columnInfo = [];
      this.keys = [];
      this.clickValue = [];
      this.labelData = [];
      this.showLabelData = false;
      this.modaldialog = false;
      this.excelText = "";
      this.$refs.SearchItemForm.resetValidation();
    },
    // file name
    fileName() {
      this.excelName =
        "Search Details" + "_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
    },
    fileNamePopup() {
      this.excelName =
        this.excelText + "_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
    },
    //Search button click event
    searchVal() {
      this.searchItems = [];
      if (!this.$refs.SearchItemForm.validate()) return false;
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let searchDetails = {
        UserId: parseInt(this.userId),
        lookup: this.searchValue,
        pass: "",
      };
      this.axios
        .post("/ut/onhand_lookup_search", searchDetails)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responsedata = JSON.parse(response.data.body.message);
            this.resultData = [];
            this.columnInfo = [];
            if (responsedata !== null && responsedata !== undefined) {
              this.resultData = responsedata.ResultData;
              this.columnInfo = responsedata.ColumnsInfo;
              let headers = responsedata.ColumnsInfo.map((obj) => {
                return obj.colname;
              });
              let keys = Object.keys(this.resultData[0]);
              for (let i = 0; i < headers.length; i++) {
                for (let k = 0; k < keys.length; k++) {
                  if (headers[i] == keys[k]) {
                    this.headerData.push(keys[k]);
                  }
                }
              }
              this.headerData = [...new Set(this.headerData)];
              let header = JSON.stringify(this.headerData);
              let result = this.resultData;
              let newArray = [];
              let newObj = {};
              result.map((x) => {
                newObj = {};
                Object.entries(x).forEach((entry) => {
                  var [key, value] = entry;
                  if (header.includes(key)) {
                    newObj["Row Id"] = x["Row Id"];
                    newObj[key] = value;
                  }
                });
                newArray.push(newObj);
              });
              if (newArray !== undefined && newArray !== null) {
                this.searchItems = newArray;
                let headKeys = Object.keys(this.searchItems[0]);
                this.headersSearch = headKeys.map((x) => ({
                  text: x,
                  value: x,
                  sortable: false,
                  class: "primary customwhite--text",
                }));
                this.headersSearch[0].class = "primary primary--text";
                this.dataSearched = true;
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            }
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //On click of hyperlink
    onClickItem(columName, value, props) {
      this.excelText = "";
      this.excelText = columName;
      this.clickValue = this.columnInfo.filter((obj) => columName === obj.colname);
      if (this.clickValue[0].pass !== " " && this.clickValue[0].isLink !== "") {
        this.pass = this.clickValue[0].pass;
        if (this.clickValue[0].isLink) {
          let LoaderDialog = {
            visible: true,
            title: "Please Wait...",
          };
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let searchUrlDetails = {
            UserId: parseInt(this.userId),
            lookup: value,
            pass: this.pass,
          };
          this.axios
            .post("/ut/onhand_lookup_search", searchUrlDetails)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {
                this.headerData = [];
                this.headersSearch = [];
                this.searchItems = [];
                let responsedata = JSON.parse(response.data.body.message);
               
                let resultDetails = responsedata.ResultData;
                let columnDetails = responsedata.ColumnsInfo;
                if (responsedata.LabelData !== null) {
                  this.labelData = responsedata.LabelData[0];
                  this.showLabelData = true;
                }
                let headers = columnDetails.map((obj) => {
                  return obj.colname;
                });
                let keys = Object.keys(resultDetails[0]);
                for (let i = 0; i < headers.length; i++) {
                  for (let k = 0; k < keys.length; k++) {
                    if (headers[i] == keys[k]) {
                      this.headerData.push(keys[k]);
                    }
                  }
                }
                this.headerData = [...new Set(this.headerData)];
                let header = JSON.stringify(this.headerData);
                let result = resultDetails;
                let newArray = [];
                let newObj = {};
                result.map((x) => {
                  newObj = {};
                  Object.entries(x).forEach((entry) => {
                    var [key, value] = entry;
                    if (header.includes(key)) {
                      newObj["Row Id"] = x["Row Id"];
                      newObj[key] = value;
                    }
                  });
                  newArray.push(newObj);
                });
                this.searchItems = newArray;
                let headKeys = Object.keys(this.searchItems[0]);
                this.headersSearch = headKeys.map((x) => ({
                  text: x,
                  value: x,
                  sortable: false,
                  class: "primary customwhite--text",
                }));
                this.headersSearch[0].class = "primary primary--text";
                this.dataSearched = true;
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              } else {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            })
            .catch((error) => {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.errorMessage = error.message;
            });
        }
      } else if (this.clickValue[0].valcol !== "" && this.clickValue[0].valcol !== null) {
        if (JSON.stringify(props).includes("Total")) {
          let Alert = {
            type: "error",
            isShow: true,
            message: "No details are available",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        } else {
          let clickDetails = this.resultData.find((item) => {
            if (item["Row Id"] == props["Row Id"]) {
              return item[this.clickValue[0].valcol];
            }
          });
          if (clickDetails !== undefined && clickDetails !== null) {
            let LoaderDialog = {
              visible: true,
              title: "Please Wait...",
            };
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let searchUrlDetails = {
              input: clickDetails[this.clickValue[0].valcol],
            };
            this.axios
              .post("ut/onhand_lookup_drill", searchUrlDetails)
              .then((response) => {
                if (response.status == 200 || response.status == 204) {
                  this.headerData = [];
                  this.headersPopup = [];
                  this.searchPopup = [];
                  let responsedata = JSON.parse(response.data.body.message);
                  this.modaldialog = true;
                  if (responsedata !== null && responsedata !== undefined) {
                    let resultDetails = responsedata.ResultData;
                    let columnsData = responsedata.ColumnsInfo;
                    let headers = columnsData.map((obj) => {
                      return obj.colname;
                    });
                    let keys = Object.keys(resultDetails[0]);
                    for (let i = 0; i < headers.length; i++) {
                      for (let k = 0; k < keys.length; k++) {
                        if (headers[i] == keys[k]) {
                          this.headerData.push(keys[k]);
                        }
                      }
                    }
                    this.headerData = [...new Set(this.headerData)];
                    let header = JSON.stringify(this.headerData);
                    let result = resultDetails;
                    let newArray = [];
                    let newObj = {};
                    result.map((x) => {
                      newObj = {};
                      Object.entries(x).forEach((entry) => {
                        var [key, value] = entry;
                        if (header.includes(key)) {
                          newObj[key] = value;
                        }
                      });
                      newArray.push(newObj);
                    });
                    this.searchPopup = newArray;
                    let headKeys = Object.keys(this.searchPopup[0]);
                    this.headersPopup = headKeys.map((x) => ({
                      text: x,
                      value: x,
                      sortable: false,
                      class: "primary customwhite--text",
                    }));
                    let Alert = {
                      type: "success",
                      isShow: true,
                      message: response.data.status_code,
                    };
                    this.$store.commit("ConfigModule/Alert", Alert);
                    LoaderDialog.visible = false;
                    this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  }
                } else {
                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  let Alert = {
                    type: "error",
                    isShow: true,
                    message: response.data.message,
                  };
                  this.$store.commit("ConfigModule/Alert", Alert);
                }
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              })
              .catch((error) => {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: Utility.apiError(error),
                };
                this.$store.commit("ConfigModule/Alert", Alert);
                this.errorMessage = error.message;
              });
          } else {
            let Alert = {
              type: "error",
              isShow: true,
              message: "No details are available",
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        }
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "No details are available",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Closing popup dialog
    closeDialogDataTable() {
      this.modaldialog = false;
    },
  },
  components: {
    breadcrumbComp,
  },
};
